import React, { Fragment} from 'react';
// import { Settings } from 'react-feather';
// import { Link } from 'react-router-dom';
import { H6, Image, /*LI,*/ UL, P } from '../../AbstractElements';
// import man from '../../assets/images/dashboard/1.png';
import { useTranslation } from 'react-i18next';

function isObject(item) {
    return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const Profile = () => {
    const userData = JSON.parse(localStorage.getItem('user_data'));
    const userRoles = JSON.parse(localStorage.getItem('auth0_user_roles'));
    const rolesIsObj = isObject(userRoles);
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                {userData !== null ? (
                    <>
                        <Image attrImage={{ className: 'img-90 rounded-circle', src: userData.picture, alt: '' }} />
                        <H6 attrH6={{ className: 'mt-3 f-14 f-w-600' }} >{userData.nickname}</H6>
                        {/* </Link> */}
                        {/* <H6 attrH6={{ className: 'mt-3 f-14 f-w-600' }} >{t('Enabled roles')}</H6> */}
                        <UL attrUL={{ className: 'flex-row simple-list' }}>
                            {!rolesIsObj && (userRoles.map((role, index) => (
                                <li key={index}>
                                    <P>{role}</P>
                                </li>
                            )))}
                        </UL>
                    </>
                ) : (
                    <p>{t('Not logged in')}</p>
                )}
            </div>
        </Fragment >
    );
};

export default Profile;