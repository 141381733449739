/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getUsers, addUsers } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { FormGroup, Label, Container, Modal, Table, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Trash2, Eye, EyeOff, Plus } from 'react-feather';
import { getAuthzRoles } from '../../../api/api';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

function AddUsers() {
  const { t } = useTranslation();
  const { basictoaster, primary, setUsers, setLoading, setExecuting } = useStateContext();
  // init form data
  const [selectedRole, setSelectedRole] = useState('noRole');
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    passwordConfirm: '',
    roles: [],
  });
  const [authzRoles, setAuthzRoles] = useState(false);
  const [viewPass, setViewPass] = useState(false);
  const [errors, setErrors] = useState({});
  // open
  const [open, setOpen] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  const handleOpen = () => {
    // Create a new AbortController instance
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    // get all roles from api
    // get selections for all the form selects
    getAuthzRoles(newAbortController.signal).then((resp) => {
      if (!newAbortController.signal.aborted) {
        setAuthzRoles(resp);
      }
    }).catch((err) => {basictoaster("error", 'Errore ruoli', err, () => {})})
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    abortController.abort();
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // add role
  const addRole = () => {
    // if is not the default select option set the state
    if (selectedRole !== 'noRole') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        roles: [...prevFormData.roles, selectedRole],
      }));
    }
    // reset the select role to default
    setSelectedRole('noRole')
  };

  // remove role
  const removeRole = (roleToRemove) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      roles: prevFormData.roles.filter((role) => role !== roleToRemove),
    }));
  };

  // validate from callback
  const validateForm = useCallback(() => {
    const { username, password, passwordConfirm } = formData;
    const newErrors = {};

    if (!username) {
      newErrors.username = 'Il nome utente è richiesto';
    }
    if (!password) {
      newErrors.password = 'La password è richiesta';
    }
    if (password !== passwordConfirm) {
      newErrors.passwordConfirm = 'Le due password non corrispondono';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  // validate form if input changes
  useEffect(() =>  {
    validateForm();
  }, [formData, validateForm])

  const adduserHandler = () => {
    SweetAlert.fire({
        title: 'Sei sicuro?',
        text: 'Il tuo utente verrà aggiunto',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const userObj = {
              username: formData.username,
              password: formData.password,
              roles: formData.roles,
            };
            // console.log(connAgreement)
            await addUsers(userObj).then(async (response) => {
              getUsers().then((newsResp) => {
                setUsers(newsResp);
                setLoading(false);
              }).catch((err) => {basictoaster("error", "Errore utenti", err, () => {})});
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      'Fatto!',
                      'Il tuo utente è stato aggiunto.',
                      'success'
                  );
                  // init form data
                  setFormData({
                    username: '',
                    password: '',
                    passwordConfirm: '',
                    roles: [],
                  })
              } else if (containsError(response)) {
                SweetAlert.fire(
                    'Errore!',
                    `Errore: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
              setOpen(false);
            }).catch((err) => {basictoaster("error", 'Errore aggiunta utenti', err, () => {})});
          }
        });
  };

  // submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      adduserHandler();
    }
  };

  // ALERT
  useEffect(() => {
    if (isObject(authzRoles) && containsError(authzRoles)) {
      basictoaster("error", "Errore ruoli", authzRoles.Error, setAuthzRoles)
    }
  }, [authzRoles, basictoaster])

  return (
    <>
      <Button variant="contained" style={{ backgroundColor: primary }} onClick={handleOpen}>Aggiungi utente</Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '20px' }}>
          <h5>Aggiungi utente:</h5><br />
          <form style={{ margin: '10%', marginTop: '1%', marginBottom: '1%' }} onSubmit={handleSubmit} >
            {/* USERNAME */}
            <FormGroup>
              <Label htmlFor="username" className="col-form-label pt-0">Nome utente</Label>
              <input
                className="form-control"
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
              {errors.username && <div style={{ color: 'red' }}>{errors.username}</div>}
            </FormGroup>
            {/* PASSWORD */}
            <FormGroup>
              <Label htmlFor="password" className="col-form-label pt-0">Password</Label>
              <input
                className="form-control"
                type={viewPass ? 'text' : 'password'}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
            </FormGroup>
            {/* PASSWORD CONFIRM */}
            <FormGroup>
              <Label htmlFor="passwordConfirm" className="col-form-label pt-0">Conferma password</Label>
              <input
                className="form-control"
                type={viewPass ? 'text' : 'password'}
                id="passwordConfirm"
                name="passwordConfirm"
                value={formData.passwordConfirm}
                onChange={handleChange}
              />
              {errors.passwordConfirm && <div style={{ color: 'red' }}>{errors.passwordConfirm}</div>}
              <button type='button' className='imgButton' onClick={() => {setViewPass((prevState) => !prevState)}} >{viewPass ? <Eye width={20} /> : <EyeOff width={20} />}</button>
            </FormGroup>
            {/* ROLES */}
            <FormGroup>
              <Label htmlFor="roles" className="col-form-label pt-0">Ruoli</Label>
              <Input
                className="form-control"
                type="select"
                id="roles"
                name="roles"
                onChange={(e) => {setSelectedRole(e.target.value)}}
              >
                <option value="noRole">Nessun ruolo</option>
                {/* REMOVE THE ALREADY ADDED ROLE FROM THE AVIALABLE ROLES with filter */}
                {authzRoles && !containsError(authzRoles) && authzRoles.filter(role => !formData.roles.includes(role)).map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  )
                )}
              </Input>
              {selectedRole !== 'noRole' && (<button className='imgButton' onClick={addRole}><Plus width={20} /></button>)}
            </FormGroup>
            {formData.roles.length > 0 && (
              <>
                <h6>Ruoli aggiunti:</h6>
                <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
                  <Table style={{ textAlign: 'center' }}>
                    <thead className="table-primary table-responsive">
                      <tr>
                        <th>Ruolo</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {formData.roles && formData.roles.map((role) => (
                        <tr key={role}>
                          <td>{role}</td>
                          <td><button type='button' className='imgButton' onClick={() => {removeRole(role)}} ><Trash2 width={20}/></button></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div><br />
              </>
            )}
            <br />
            {/* BUTTONS */}
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: primary }}
            >
              {t('Aggiungi')}
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={handleClose}
              style={{ backgroundColor: 'gray', marginLeft: '1%' }}
            >
              {t('Cancel')}
            </Button>
          </form>
        </Container>
      </Modal>
    </>
  );
}

export default AddUsers;
