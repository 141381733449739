/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { Spinner } from '../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getGuestsFn } from '../../../api/api';
import GuestsTable from './GuestsTable';
import GuestSearchBar from '../GuestsMovements/SelectGuest/GuestSearchBar';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const Guests = () => {
  const { basictoaster, loading, setLoading, guests, setGuests } = useStateContext();

  useEffect(() => {
    // set ab to state and use this new ab in this code
    let newAbortController = new AbortController()
    setLoading(true);
    getGuestsFn(newAbortController.signal).then((newsResp) => {
      if (!newAbortController.signal.aborted) {
        setGuests(newsResp);
        setLoading(false);
      }
    }).catch((err) => {basictoaster("error", "Errore ospiti", err, () => {})});
    
    // Cleanup function to abort the request when the component unmounts
    return () => {
      newAbortController.abort();
    };
  }, [])

  // ALERT
  useEffect(() => {
    if (isObject(guests) && containsError(guests)) {
      basictoaster("error", "Errore ospiti", guests.Error, setGuests)
    }
  }, [guests])

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle="Ospiti" parent="DomusVestra" title="Ospiti" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                {/* <NewReq /><br /><br /> */}
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  <>
                    <h2><b>Ospiti</b></h2><br />
                    <GuestSearchBar fromGuestsPage /><br />
                    <GuestsTable />
                  </>
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Guests;