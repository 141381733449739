/* eslint-disable no-console */
function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsError2(item) {
    return Object.prototype.hasOwnProperty.call(item, 'error');
}

// const reEnableTheProductionApi = ""
// const apiServer = "192.168.254.123:8080"
const apiServer = "api.intranet.rsm.residenzasantamargherita.it"

// AUTH0

// -------------------- AUTH0 LOGIN ----------------------------------
async function fetchLogin(lObj, setLoginResp) {
  try {
    const response = await fetch(`https://${apiServer}/login`, {
      method: 'POST',
      body: JSON.stringify(lObj),
    });
    const data = await response.json();
    // console.log(data.access_token);
    // if data contains error don't set token
    if (!containsError2(data)) {
      localStorage.setItem('auth0_token', data.access_token);
    }
    setLoginResp(data);
    console.log('login function');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    setLoginResp(error);
    throw error;
  }
}
//----------------------------------------------------------------

// -------------------- AUTH0 VALIDATE TOKEN ----------------------------------
async function validateToken() {
  try {
    const token = localStorage.getItem('auth0_token');
    const response = await fetch(`https://${apiServer}/validate_token`, {
      method: 'POST',
      body: token,
    });
    const data = await response.json();
    // console.log(data);
    console.log('validate token function');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//-------------------------------------------------------------------

// -------------------- AUTH0 RESET USER PASS ----------------------------------
async function resetUserPassFn(resetObj) {
  try {
    const response = await fetch(`https://${apiServer}/users/password`, {
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
      },
      body: JSON.stringify(resetObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('reset user pass');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//----------------------------------------------------------------

// // -------------------- AUTH0 RESET USER PASS ----------------------------------
// async function resetUserPass(mail) {
//   try {
//     const response = await fetch(`https://${apiServer}/user_reset_password`, {
//       method: 'POST',
//       body: mail,
//     });
//     const data = await response.json();
//     // console.log(data);
//     console.log('reset user pass function');
//     if (containsError(data) && data.Error.includes("ExpiredSignature")) {
//       localStorage.removeItem('logged');
//       window.location.href = '/login';
//     };
//     return data;
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// }
// //-------------------------------------------------------------------


// DATABASE

// -------------------------------- BEG GUESTS MOVEMENTS -----------------------------------------------------------

// GET GUESTS MOVEMENTS
async function getGuestsMovementsFn(date, signal) {
  try {
    let token = localStorage.getItem('auth0_token')
    let headers = {};
    if (token !== null) {
      headers = {
        Authorization: token,
      }
    }
    const response = await fetch(`https://${apiServer}/guests_movements/${date}`, {
      method: 'GET',
      headers: headers,
      signal: signal
    });
    const data = await response.json();
    // console.log(data);
    console.log('get guests movements');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("User not from local, not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.log(error);
    throw error;
  }
}

// ADD GUESTS MOVEMENTS
async function addGuestMovementFn(movObj) {
  try {
    const response = await fetch(`https://${apiServer}/guests_movements`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
      },
      body: JSON.stringify(movObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('add guest movement');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// UPDATE GUESTS MOVEMENTS
async function updateGuestMovementFn(movObj) {
  try {
    const response = await fetch(`https://${apiServer}/guests_movements`, {
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
      },
      body: JSON.stringify(movObj),
    });
    const data = await response.text();
    console.log(data);
    console.log('update guest movement');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// APPLY GUESTS MOVEMENTS
async function applyMovementsFn(signal) {
  try {
    let token = localStorage.getItem('auth0_token')
    let headers = {};
    if (token !== null) {
      headers = {
        Authorization: token,
      }
    }
    const response = await fetch(`https://${apiServer}/apply_movements`, {
      method: 'GET',
      headers: headers,
      signal: signal
    });
    const data = await response.json();
    // console.log(data);
    console.log('apply guest movements');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.log(error);
    throw error;
  }
}

// DELETE GUESTS MOVEMENTS
async function deleteGuestsMovementsFn(movId) {
  try {
    const response = await fetch(`https://${apiServer}/guests_movements/${movId}`, {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
      },
    });
    const data = await response.json();
    // console.log(data);
    console.log('delete guests movements');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// GET ROOMS
async function getRooms(signal) {
  try {
    let token = localStorage.getItem('auth0_token')
    let headers = {};
    if (token !== null) {
      headers = {
        Authorization: token,
      }
    }
    const response = await fetch(`https://${apiServer}/rooms`, {
      method: 'GET',
      headers: headers,
      signal: signal
    });
    const data = await response.json();
    // console.log(data);
    console.log('get rooms');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.log(error);
    throw error;
  }
}

// -------------------------------- END GUESTS MOVEMENTS -----------------------------------------------------------


// ------------------------------------------ BEG GUESTS -----------------------------------------------------------


// GET GUESTS
async function getGuestsFn(signal) {
  try {
    let token = localStorage.getItem('auth0_token')
    let headers = {};
    if (token !== null) {
      headers = {
        Authorization: token,
      }
    }
    const response = await fetch(`https://${apiServer}/guests`, {
      method: 'GET',
      headers: headers,
      signal: signal
    });
    const data = await response.json();
    // console.log(data);
    console.log('get guests');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.log(error);
    throw error;
  }
}

// UPDATE GUESTS
async function updateGuestFn(guestObj) {
  try {
    const response = await fetch(`https://${apiServer}/guests`, {
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
      },
      body: JSON.stringify(guestObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('update guest');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// ------------------------------------------ END GUESTS -----------------------------------------------------------


// -------------------------------- BEG FIREFIGHTERS SHIFTS -----------------------------------------------------------

// GET FIREFIGHTERS SHIFTS
async function getFireFightersShifts(date, signal) {
  try {
    let token = localStorage.getItem('auth0_token')
    let headers = {};
    if (token !== null) {
      headers = {
        Authorization: token,
      }
    }
    const response = await fetch(`https://${apiServer}/firefighters_shifts/${date}`, {
      method: 'GET',
      headers: headers,
      signal: signal
    });
    const data = await response.json();
    // console.log(data);
    console.log('get firefighters shifts');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("User not from local, not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.log(error);
    throw error;
  }
}

// -------------------------------- END FIREFIGHTERS SHIFTS -----------------------------------------------------------


// -------------------------------- BEG NEWS -----------------------------------------------------------

// GET NEWS
async function getNewsFn(number, signal) {
  try {
    let token = localStorage.getItem('auth0_token')
    let headers = {};
    if (token !== null) {
      headers = {
        Authorization: token,
      }
    }
    const response = await fetch(`https://${apiServer}/news/${number}`, {
      method: 'GET',
      headers: headers,
      signal: signal
    });
    const data = await response.json();
    // console.log(data);
    console.log('get news');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("User not from local, not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.log(error);
    throw error;
  }
}

// ADD NEWS
async function addNewsFn(newsObj) {
  try {
    const response = await fetch(`https://${apiServer}/news`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
      },
      body: JSON.stringify(newsObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('add news');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// UPDATE NEWS
async function updateNewsFn(newsObj) {
  try {
    const response = await fetch(`https://${apiServer}/news`, {
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
      },
      body: JSON.stringify(newsObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('add news');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// -------------------------------- END NEWS -----------------------------------------------------------


// -------------------------------- BEG DOCUMENTS ------------------------------------------------------

// GET DOCUMENTS
async function getDocuments(signal) {
  try {
    let token = localStorage.getItem('auth0_token')
    let headers = {};
    if (token !== null) {
      headers = {
        Authorization: token,
      }
    }
    const response = await fetch(`https://${apiServer}/documents`, {
      method: 'GET',
      headers: headers,
      signal: signal
    });
    const data = await response.json();
    // console.log(data);
    console.log('get documents');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("User not from local, not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.log(error);
    throw error;
  }
}

// GET DOCUMENT LINK
async function getDocumentContent(documentId, signal) {
  try {
    let token = localStorage.getItem('auth0_token')
    let headers = {};
    if (token !== null) {
      headers = {
        Authorization: token,
      }
    }
    const response = await fetch(`https://${apiServer}/document/${documentId}`, {
      method: 'GET',
      headers: headers,
      signal: signal
    });
    const data = await response.json();
    // console.log(data);
    console.log('get document content');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("User not from local, not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.log(error);
    throw error;
  }
}


// -------------------------------- END DOCUMENTS ------------------------------------------------------


// -------------------------------- BEG USERS MANAGEMENT -----------------------------------------------

// GET USERS
async function getUsers(signal) {
  try {
    let token = localStorage.getItem('auth0_token')
    let headers = {};
    if (token !== null) {
      headers = {
        Authorization: token,
      }
    }
    const response = await fetch(`https://${apiServer}/users`, {
      method: 'GET',
      headers: headers,
      signal: signal
    });
    const data = await response.json();
    // console.log(data);
    console.log('get users');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.log(error);
    throw error;
  }
}

// GET AVIALABLE ROLES
async function getAuthzRoles(signal) {
  try {
    let token = localStorage.getItem('auth0_token')
    let headers = {
      Authorization: token,
    };
    const response = await fetch(`https://${apiServer}/authz_roles`, {
      method: 'GET',
      headers: headers,
      signal: signal
    });
    const data = await response.json();
    // console.log(data);
    console.log('get authz roles');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data
  } catch (error) {
    if (error.name === 'AbortError') {
      return;
    }
    console.log(error);
    throw error;
  }
}

// ADD USERS
async function addUsers(usrObj) {
  try {
    const response = await fetch(`https://${apiServer}/users`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
      },
      body: JSON.stringify(usrObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('add users');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// UPDATE USERS
async function updateUsers(usrObj) {
  try {
    const response = await fetch(`https://${apiServer}/users`, {
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
      },
      body: JSON.stringify(usrObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('update users');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// CHANGE USER PASSWORD
async function changeUserPasswordFn(chObj) {
  try {
    const response = await fetch(`https://${apiServer}/ow_user_password`, {
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
      },
      body: JSON.stringify(chObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('change user password');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// DELETE USERS
async function deleteUsers(usrId) {
  try {
    const response = await fetch(`https://${apiServer}/users/${usrId}`, {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
      },
    });
    const data = await response.json();
    // console.log(data);
    console.log('delete users');
    if (containsError(data) && data.Error.includes("ExpiredSignature")) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// -------------------------------- END USERS MANAGEMENT -----------------------------------------------

export {
  fetchLogin, validateToken, resetUserPassFn, 
  getGuestsMovementsFn, addGuestMovementFn, updateGuestMovementFn, applyMovementsFn, deleteGuestsMovementsFn, getRooms,
  getGuestsFn, updateGuestFn,
  getFireFightersShifts,
  getNewsFn, addNewsFn, updateNewsFn,
  getDocuments, getDocumentContent,
  getUsers, getAuthzRoles, addUsers, updateUsers, changeUserPasswordFn, deleteUsers
};