/* eslint-disable object-property-newline */
import React, { createContext, useContext, useState } from 'react';
import ConfigDB from '../Config/ThemeConfig';
import { toast } from 'react-toastify';
const htmlparser2 = require('htmlparser2');
const { DomHandler } = require('domhandler');
const { default: serialize } = require('dom-serializer');

toast.configure();
  const basictoaster = (toastname, notifyTxt, notifyMsg, itemsDel) => {
    switch (toastname) {
      case 'success':
        toast.success(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      case 'info':
        toast.info(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      case 'warning':
        toast.warn(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      case 'error':
        toast.error(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      default:
        break;
    }
  };

const StateContext = createContext();

// convert utc to local
function getLastSundayOfMonth(year, month) {
  // Ottiene l'ultimo giorno del mese specificato
  const lastDay = new Date(Date.UTC(year, month + 1, 0)); // Ultimo giorno del mese
  // Trova l'ultima domenica
  const lastSunday = lastDay.getUTCDate() - lastDay.getUTCDay();
  return new Date(Date.UTC(year, month, lastSunday, 2, 0, 0)); // 2:00 AM UTC per uniformità
}
function convertUtcToLocal(utcDateString) {
  // Converte la stringa di data UTC in un oggetto Date
  const utcDate = new Date(utcDateString);

  // Estrae l'anno dalla data UTC
  const year = utcDate.getUTCFullYear();

  // Calcola le date di inizio e fine dell'ora legale per l'anno
  const dstStart = getLastSundayOfMonth(year, 2); // Ultima domenica di marzo
  const dstEnd = getLastSundayOfMonth(year, 9);   // Ultima domenica di ottobre

  // Determina l'offset in base all'intervallo dell'ora legale o solare
  const isDst = utcDate >= dstStart && utcDate < dstEnd;
  const offsetHours = isDst ? 2 : 1;

  // Converte l'ora UTC in base all'offset dell'ora legale o solare
  const localDate = new Date(utcDate.getTime() + offsetHours * 60 * 60 * 1000);

  // Costruisce la stringa di data locale formattata
  const yearLocal = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Mesi indicizzati da 0
  const day = String(localDate.getDate()).padStart(2, '0');
  const hour = String(localDate.getHours()).padStart(2, '0');
  const minute = String(localDate.getMinutes()).padStart(2, '0');
  const second = String(localDate.getSeconds()).padStart(2, '0');

  // Formatta la data in 'YYYY-MM-DD HH:MM:SS'
  const localDateString = `${yearLocal}-${month}-${day} ${hour}:${minute}:${second}`;

  return localDateString;
}

function convertLocalToUtc(localDateStr) {
  // converts form input from local date to utc date to store in db
  // takes a datetime string like this from the form datetime-local input 2024-05-21 15:00
  const localDateObj = new Date(localDateStr);
  let year = localDateObj.getUTCFullYear();
  let month = String(localDateObj.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  let day = String(localDateObj.getUTCDate()).padStart(2, '0');
  let hour = String(localDateObj.getUTCHours()).padStart(2, '0');
  let minute = String(localDateObj.getUTCMinutes()).padStart(2, '0');
  let second = String(localDateObj.getUTCSeconds()).padStart(2, '0');
  
  // Format the date to '2024-05-21T13:00' to save in the db datetime field
  const utcDateString = `${year}-${month}-${day}T${hour}:${minute}:${second}`;

  return utcDateString;
}

function formatDateToItalian(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

function formatDateTimeToItalian(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

const todayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Ensure two digits
  const date = String(today.getDate()).padStart(2, '0'); // Ensure two digits
  return `${year}-${month}-${date}`
}

function getWeekdayName(dateString) {
  // Takes "2024-05-23" and returns the weekday name with a capital starting letter
  const date = new Date(dateString);
  const options = { weekday: 'long' };
  let weekdayName = new Intl.DateTimeFormat('it-IT', options).format(date);
  // Capitalize the first letter
  weekdayName = weekdayName.charAt(0).toUpperCase() + weekdayName.slice(1);
  return weekdayName;
}

// Function to convert date format
const convertDateYearAtEnd = (dateString) => {
  // takes "2024-05-23" transforms to "03-25-2024"
  const [year, month, day] = dateString.split('-');
  const newDateString = `${day}-${month}-${year}`;
  return newDateString;
};

// Get today date but minus 8 days
const getDateMinus8Days = () => {
  const today = new Date();
  const dateMinus8Days = new Date(today);
  dateMinus8Days.setDate(today.getDate() - 8);
  const year = dateMinus8Days.getFullYear();
  const month = String(dateMinus8Days.getMonth() + 1).padStart(2, '0');
  const day = String(dateMinus8Days.getDate()).padStart(2, '0');
  console.log(`${year}-${month}-${day}`)
  return `${year}-${month}-${day}`;
};

// shortens text
const shortenText = (text, length) => {
  if (text.length > length) {
    return text.substring(0, length) + '...';
  }
  return text;
};

// put b64 header if is missing
function addB64HeadersIfMissing(b64) {
  if (b64.startsWith('data')) {
    return b64
  } else {
    let b64WithHeaders = `data:image/png;base64,${b64}`
    return b64WithHeaders
  }
}

function trimHTML(html, maxChars) {
  const handler = new DomHandler();
  const parser = new htmlparser2.Parser(handler);
  parser.write(html);
  parser.end();

  let charCount = 0;
  let stop = false;

  function traverse(nodes) {
    return nodes.map(node => {
      if (stop) return null;

      if (node.type === 'text') {
        if (charCount + node.data.length > maxChars) {
          node.data = node.data.slice(0, maxChars - charCount) + '...';;
          stop = true;
        }
        charCount += node.data.length;
      } else if (node.children && node.children.length > 0) {
        node.children = traverse(node.children);
      }
      return node;
    }).filter(node => node !== null);
  }

  const trimmedDom = traverse(handler.dom);
  return serialize(trimmedDom);
}



export const ContextProvider = ({ children }) => {
  const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;

  const [loginResp, setLoginResp] = useState({});
  const [alertOn, setAlertOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [guestsMovements, setGuestsMovements] = useState([]);
  const [guests, setGuests] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [fireFightersShifts, setFireFightersShifts] = useState([]);
  const [news, setNews] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [users, setUsers] = useState([]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider value={{
      basictoaster,
      convertUtcToLocal, convertLocalToUtc, formatDateToItalian, formatDateTimeToItalian,
      todayDate, getWeekdayName, convertDateYearAtEnd, getDateMinus8Days,
      shortenText,
      addB64HeadersIfMissing,
      trimHTML,
      primary,
      loginResp, setLoginResp,
      alertOn, setAlertOn,
      loading, setLoading,
      executing, setExecuting,
      guestsMovements, setGuestsMovements,
      guests, setGuests,
      rooms, setRooms,
      fireFightersShifts, setFireFightersShifts,
      news, setNews,
      documents, setDocuments,
      users, setUsers
    }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
