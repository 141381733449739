/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Container, Modal } from 'reactstrap';
import { Eye } from 'react-feather';
import { useStateContext } from '../../../../contexts/ContextProvider';
import NewsImagesCarousel from '../NewsCards/NewsImagesCarousel';
import NewsPdfsCarousel from './NewsPdfsCarousel'

function ViewNews({ sNews }) {
  const { primary, convertUtcToLocal, formatDateTimeToItalian } = useStateContext();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }

  return (
    <>
      <Button variant='contained' style={{ backgroundColor: primary }} onClick={handleOpen} ><Eye width={20} style={{ marginLeft: '%5' }} /></Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        size='xl'
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '30px', textAlign: 'center' }} >
          <h3><b>{sNews.title}</b></h3>
          <p style={{ fontSize: '130%' }} >{formatDateTimeToItalian(convertUtcToLocal(sNews.publish_date))}</p>
          <hr />
          {/* NEWS BODY */}
          <div dangerouslySetInnerHTML={{ __html: sNews.body }} />
          {sNews.attachments.length > 0 && (
            <>
              <hr />
              <h4>Allegati:</h4>
              <hr style={{ width: '50%', margin: 'auto' }} />
              {/* IMAGES CAROUSEL */}
              <br />
              <h5><b>Immagini:</b></h5>
              <div style={{ width: '45%', margin: 'auto' }} >
                <NewsImagesCarousel attachments={sNews.attachments} />
              </div>
              <br /><br />
              <hr style={{ width: '50%', margin: 'auto' }} />
              {/* PDF CAROUSEL */}
              <br />
              <h5><b>PDF:</b></h5>
              <div style={{ width: '100%', margin: 'auto' }} >
                <NewsPdfsCarousel attachments={sNews.attachments} />
              </div>
              <br /><br />
            </>
          )}
          <br />
          <Button variant='contained' style={{backgroundColor: 'gray'}} onClick={handleClose}>Chiudi</Button>
        </Container>
      </Modal>
    </>
  );
}

export default ViewNews;
