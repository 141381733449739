import React, { } from 'react';
import { useStateContext } from '../../../../contexts/ContextProvider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import ViewNews from '../ViewNews/ViewNews';
import NewsImagesCarousel from './NewsImagesCarousel';
import UpdateNews from '../UpdateNews';

const NewsCards = ({ fromHome }) => {
  const { news, convertUtcToLocal, formatDateToItalian, trimHTML } = useStateContext();

  return (
    <>
      {fromHome && <h4>Notizie:</h4>}
      {news && news.length > 0 ? (
        <Grid container spacing={2}>
          {news.map((sNews) => (
            <Grid item xs={3} key={sNews.id}>
              <Card className='shadowClass'>
                {/* CAROUSEL WITH ONLY IMAGE */}
                {sNews.attachments.length > 0 && (
                  <NewsImagesCarousel attachments={sNews.attachments} />
                )}
                {/* CARD CONTENT */}
                <CardContent style={{ textAlign: 'center' }}>
                  <h5><b>{sNews.title}</b></h5>
                  <p>{formatDateToItalian(convertUtcToLocal(sNews.publish_date))}</p>
                  <hr />
                  {/* NEWS BODY */}
                  <div dangerouslySetInnerHTML={{ __html: trimHTML(sNews.body, 35) }} />
                  <br />
                  <ViewNews sNews={sNews} />
                  {!fromHome && <UpdateNews oldNews={sNews}/>}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <p>Nessuna notizia trovata ...</p>
      )}
    </>
  );
};

export default NewsCards