/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { DataGrid } from '@mui/x-data-grid';
import ColoredSquare from '../../ColoredSquare/ColoredSquare';

function SelectGuestsTable({ selectGuestH }) {
  const { primary, guests } = useStateContext();
  
  const columns = [
    {
      field: 'surname',
      headerName: 'Cognome',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'room',
      headerName: 'Stanza',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'colour',
      headerName: 'Colore',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
          {params.value ? <ColoredSquare bgColor={params.value} dimension='30px' /> : <p>Non specificato</p>}
        </div>
      ),
    },
    {
      field: 'select',
      headerName: '',
      headerClassName: 'datagrid-table-custom-headers',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: 'center' }}>
        <td><Button variant='contained' style={{ backgroundColor: primary }} onClick={() => {selectGuestH(params.row)}}>Seleziona</Button></td>
        </div>
      ),
    },
  ];

  return (
    <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
      <div style={{ width: '100%' }} className='scrollbar-data-grid'>
        <DataGrid
          rows={guests}
          columns={columns}
          getRowId={(row) => row.id}
          initialState={{
            pagination: {
              // if is from home set the defualt pagesize to 5 so it's shorter otherwise set it to 10
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15]}
          rowHeight={80}
          rowSelection={false}
        />
      </div>
    </div>
  );
}

export default SelectGuestsTable;
