/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getNewsFn, addNewsFn, } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { FormGroup, Label, Container, Modal, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Trash2 } from 'react-feather';
import TextEditor from './TextEditor';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

const readFileContent = (file) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
          // replace 'data:text/plain;base64,' to avoid issue with trailing input
          resolve(event.target.result.replace("data:text/plain;base64,", ''));
      };

      reader.onerror = (err) => {
          reject(err);
      };

      reader.readAsDataURL(file);
      // reader.readAsArrayBuffer(file);
  });
};

function AddNews() {
  const { t } = useTranslation();
  const { basictoaster, primary, setNews, setLoading, setExecuting } = useStateContext();
  // init form data
  const [formData, setFormData] = useState({
    title: '',
    body: '',
  });
  const [attachments, setAttachments] = useState([]);
  const [errors, setErrors] = useState({});

  // open
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeBody = useCallback((html) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      body: html,
    }));
  }, []);

  // handle add attachment
  const handleAddAttachment = (e) => {
    const file = e.target.files[0];
    if (file) {
      readFileContent(file).then((content) => {
        // if the attachment is a filtype that is accepted add it otherwise notify
        if (file.name.endsWith('png') || file.name.endsWith('jpg') || file.name.endsWith('jpeg') || file.name.endsWith('webp') || file.name.endsWith('pdf')) {
          // add the selected attachment to the attachments array
          let attachmentObj = {
            name: file.name,
            content: content
          };
          setAttachments([...attachments, attachmentObj]);
        } else {
          basictoaster("error", 'Formato non accettato', 'Il file selezionato è di un formato non accettato', () => {})
        }
      });
    }
  };

  // remove attachment from array
  const removeAttachment = (name) => {
    setAttachments(attachments.filter(item => item.name !== name));
  };


  // validate from callback
  const validateForm = useCallback(() => {
    const { title, body } = formData;
    const newErrors = {};

    if (!title) {
      newErrors.title = 'il titolo è richiesto';
    }
    if (!body) {
      newErrors.body = 'il corpo è richiesto';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  // validate form if input changes
  useEffect(() =>  {
    validateForm();
  }, [formData, validateForm])

  const addNewsHandler = () => {
    SweetAlert.fire({
        title: 'Sei sicuro?',
        text: 'La tua notizia verrà aggiunta',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const sNews = {
              id: 0,
              title: formData.title,
              body: formData.body,
              publish_date: new Date().toISOString().slice(0, -5),
              attachments: attachments
            };
            // console.log(connAgreement)
            await addNewsFn(sNews).then(async (response) => {
              getNewsFn(5).then((newsResp) => {
                setNews(newsResp);
                setLoading(false);
              }).catch((err) => {basictoaster("error", "Errore notizie", err, () => {})});
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      'Fatto!',
                      'La tua notizia è stata aggiunta.',
                      'success'
                  );
                  // init form data
                  setFormData({
                    title: '',
                    body: '',
                  })
                  setAttachments([])
              } else if (containsError(response)) {
                SweetAlert.fire(
                    'Errore!',
                    `Errore: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
              setOpen(false);
            }).catch((err) => {basictoaster("error", 'Errore aggiunta news', err, () => {})});
          }
        });
  };

  // submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      addNewsHandler();
    }
  };

  return (
    <>
      <Button variant="contained" style={{ backgroundColor: primary }} onClick={handleOpen}>Aggiungi notizia</Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
        size='lg'
      >
        <Container fluid={true} style={{ padding: '20px' }}>
          <h5>Aggiungi notizia:</h5><br />
          <form style={{ margin: '10%', marginTop: '1%', marginBottom: '1%' }} onSubmit={handleSubmit} >
            {/* TITLE */}
            <FormGroup>
              <Label htmlFor="title" className="col-form-label pt-0">Titolo</Label>
              <input
                className="form-control"
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
              {errors.title && <div style={{ color: 'red' }}>{errors.title}</div>}
            </FormGroup>
            {/* BODY */}
            <FormGroup>
              <Label htmlFor="body" className="col-form-label pt-0">Corpo</Label>
              <TextEditor
                onChange={handleChangeBody}
                height={'320px'}
                defaultHtml=""
              />
              {errors.body && <div style={{ color: 'red' }}>{errors.body}</div>}
            </FormGroup>
            {/* FILE UPLOAD */}
            <FormGroup>
              <Label htmlFor="file" className="col-form-label pt-0">Allegati</Label>
              <p>formati accettati: PNG, JPG, JPEG, WEBP, PDF</p>
              <input
                className="form-control"
                type="file"
                id="file"
                name="file"
                onChange={handleAddAttachment}
              />
              {errors.file && <div style={{ color: 'red' }}>{errors.file}</div>}
            </FormGroup>
            {attachments.length > 0 && (
              <>
                <h6>Allegati aggiunti:</h6>
                <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
                  <Table style={{ textAlign: 'center' }}>
                    <thead className="table-primary table-responsive">
                      <tr>
                        <th>Nome</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {attachments && attachments.map((attachment, index) => (
                        <tr key={index}>
                          <td>{attachment.name}</td>
                          <td><button type='button' className='imgButton' onClick={() => {removeAttachment(attachment.name)}} ><Trash2 width={20}/></button></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div><br />
              </>
            )}
            <br />
            {/* BUTTONS */}
            <Button
              variant="contained"
              type="submit"
              style={{ backgroundColor: primary }}
            >
              {t('Aggiungi')}
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={handleClose}
              style={{ backgroundColor: 'gray', marginLeft: '1%' }}
            >
              {t('Cancel')}
            </Button>
          </form>
        </Container>
      </Modal>
    </>
  );
}

export default AddNews;
